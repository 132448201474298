.loginContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-wrap: wrap; /* Enable wrapping for smaller screens */
}

.brandContainer {
    flex: 1; /* Takes up 50% of the space */
    display: flex;
    align-items: center;
    justify-content: center;
}

.brandmark {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
}

.formContainer {
    flex: 1; /* Takes up 50% of the space */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 400px; /* Maximum width of the form */
}

.logintitle, .loginsubtitle {
    text-align: center;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.loginButton {
    margin-top: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .brandContainer {
        display: none; /* Hide brand image on smaller screens */
    }

    .formContainer {
        flex: 0 0 100%; /* Take full width on smaller screens */
    }
}
