  .colors {
    position: absolute;
    bottom: 28px;
    margin-left: 16px;
    align-items: center;
    height: 48px;
  }

  .color {
    display: inline-block;
    height: 38px;
    width: 38px;
    border-radius: 50%;
    margin-right: 2px;
    margin-left: 2px;

  }

  .clr-btn {
    display: inline-block;
    height: 38px;
    width: 38px;
    border-radius: 50%;
    margin-right: 2px;
    margin-left: 2px;

  }

  .color-selected {
    height: 48px;
    width: 48px;
  }

  .color.black {
    background-color: black;
  }

  .color.red {
    background-color: red;
  }

  .color.green {
    background-color: green;
  }

  .color.blue {
    background-color: blue;
  }

  .color.yellow {
    background-color: yellow;
  }