.brandmark {
        width: auto;
        margin: 0 auto;
        max-width: 569px;
        max-height: 569px;
        opacity: .5;
    padding-top: 50px;

    /* @media only screen and (max-height: 700px) { 
        height: 400px;
     }

     @media only screen and (max-height: 625px) { 
        height: 200px;
     } */
    }

.webexlink {


}