.App {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.wxc-meeting {
  min-height: calc(100dvh - 56px) !important;

}

.wxc-meeting-info__title {
  display: none !important;
}

.wxc-meeting__logo {
  display: none !important;
}

.wxc-meeting {
  border: none !important;
  box-shadow: none !important;
}

.wxc-local-media__video {
  background-color: #282c34;
  border-radius: 0;
}

input[type="text"]
{
    /* enlarge by 16/12 = 133.33% */
    border-radius: 6.666666667px;
    font-size: 16px;
    line-height: 26.666666667px;
    padding: 6.666666667px;
    width: 133.333333333%;
    /* scale down by 12/16 = 75% */
    transform: scale(0.75);
    transform-origin: left top;
    /* remove extra white space */
    margin-bottom: -10px;
    margin-right: -33.333333333%;
}